import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import TextEditor from '../../../Components/TextEditor';
import NavigationBar from '../../../Components/NavigationBar';
import SkeletonLoader from '../../../Components/SkeletonLoader';
// datepicker module
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// notification module
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Utils
import { getUserRole } from '../../../Utils/getUserRole';
import { sendPostRequest } from '../../../Utils/sendRequest';
import { notify } from '../../../Utils/notify';
import { capitalizeFirstLetter } from '../../../Utils/capitalizeFirst';
import { formatDate } from '../../../Utils/formatDate';

// Styling
import '../../../Style/Table.css';

function CreateReport() {
	const [userRole, setUserRole] = useState('');
	const location = useLocation();

	const [reportData, setReportData] = useState({
		title: '',
		date_delivered: '',
		assessment_type: '',
		description: '',
		summary_of_findings: '',
		executive_summary: '',
		overview: '',
		summary: '',
		exploit: '',
		in_scope: '',
		out_of_scope: '',
		methodology: '',
		recommendations: '',
	});
	const [loading, setLoading] = useState(true);

	const navigationItems = [
		{
			title: 'Reports',
			url: location.pathname.startsWith('/admin')
				? '/admin/reports'
				: '/user/reports',
		},
		{
			title: 'Create Report',
			url: '',
		},
	];

	const updateReportData = (field, value) => {
		setReportData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	useEffect(() => {
		setUserRole(getUserRole());
		setLoading(false);
	}, []);

	const saveReport = async () => {
		const requestBody = {
			private_key: sessionStorage.getItem('private_key'),
			report: {
				title: reportData.title,
				assessment_type: reportData.assessment_type,
				date_delivered: formatDate(reportData.date_delivered),
				overview: reportData.overview,
				description: reportData.description,
				summary: reportData.summary,
				executive_summary: reportData.executive_summary,
				exploit: reportData.exploit,
				in_scope: reportData.in_scope,
				out_of_scope: reportData.out_of_scope,
				methodology: reportData.methodology,
				recommendations: reportData.recommendations,
				summary_of_findings: reportData.summary_of_findings
			}
		};

		console.log('REQUEST', requestBody);
		console.log('user role', userRole);

		try {
			var response = null;
			if (userRole === 'PlatformAdmin') {
				response = await sendPostRequest('/reports', requestBody);
			} else {
				response = await sendPostRequest('/reports', requestBody);
			}
			console.log('RESPONSE', response.data);
			if (response.status === 201) {
				notify('success', response.data.message);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			} else {
				notify('error', "Report wasn't created");
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		} catch (error) {
			console.error('Error creating report:', error);
			notify('error', 'An error occurred while creating the report');
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}

		// console.log('RESPONSE', response.data);
		// if (response.status === 201) {
		// 	notify('success', response.data.message);
		// 	window.scrollTo({ top: 0, behavior: 'smooth' });
		// } else {
		// 	notify('error', "Report wasn't created");
		// }
	};

	return (
		<div>
			<div className='navigation-bar'>
				<NavigationBar items={navigationItems} />
			</div>

			{loading && (
				<SkeletonLoader height={75} count={10} color={'#18283b'} />
			)}
			{!loading && (
				<>
					{Object.keys(reportData).map((key, index) => {
						return (
							<div
								className='d-flex flex-column my-4'
								key={index}
							>
								<h6>{capitalizeFirstLetter(key)}</h6>
								{key === 'date_delivered' ? (
									<DatePicker
										selected={reportData[key]}
										onChange={(date) =>
											updateReportData(key, date)
										}
										dateFormat='yyyy-MM-dd'
										className='form-control'
									/>
								) : (
									<TextEditor
										value={reportData[key]}
										onValueChange={(value) =>
											updateReportData(key, value)
										}
									/>
								)}
							</div>
						);
					})}

					<div className='d-flex flex-row gap-2'>
						<button
							onClick={saveReport}
							className='btn btn-success my-3'
						>
							Save
						</button>
					</div>

					<ToastContainer
						position='top-right'
						hideProgressBar={true}
						autoClose={2000}
						pauseOnHover
						theme='colored'
					/>
				</>
			)}
		</div>
	);
}

export default CreateReport;
